ul,
li {
  margin: 0;
  padding: 0;
  border: none;
}
html body a {
  text-decoration: none;
  font-weight: 500;
  color: #6956BA;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #333333;
  // font-size: 28px;
  // color: #262626;
  // font-weight: 500;
  // padding: 10px 0;
}
h3 {
  color: #5f56a6;
  font-size: 20px;
  font-weight: 500;
}
.text-primary {
  color: #5f56a6;
}
/* Customize the label (the checkbox-wrapper) */
.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 18px;
    width: 18px;
    background-color: #F2F3FF;
    border: 2px solid #6956BA;
    background-color: transparent;
    border-radius: 2px;
  }

  &:hover input ~ .checkmark {
    background-color: #F2F3FF;
  }
  input:checked ~ .checkmark {
    background-color: #6956BA;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 3.5px;
    top: -1px;
    width: 7px;
    height: 13px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.btn-text {
  color: #666666;
}

.redux-toastr {
  width: 100vw;
  .toastr {
    min-height: 40px;
    max-width: 375px;
    display: flex;
    > div {
      height: 100%;
      position: relative;
    }
    .close-toastr {
      top: -5px;
      right: 0px;
      opacity: 0.9;
    }
  }
  .rrt-progress-container {
    display: none;
  }
}
