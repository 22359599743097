.black {
    color: black;
}

.font-bold {
    font-weight: 700;
}

.background-text-white {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0.5rem;
}

.background-text-primary-50 {
    background-color: #F2F3FF;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0.5rem;
}

.background-text-primary-100 {
    background-color: #DDE0FF;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0.5rem;
}